import React from "react"

import "./article.scss"

const Article = ({ title, author, image, date, id }) => {
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ]
  let article_date = new Date(date)
  const currentYear = new Date().getFullYear()
  const formatedDate =
    article_date.getDate() +
    "." +
    months[article_date.getMonth()] +
    (currentYear !== article_date.getFullYear()
      ? "." + article_date.getFullYear()
      : "")

  return (
    <div className="article-component">
      <div className="image-container">
        <a href={`/article?id=${id}`}>
          <img src={image} alt={"Thumbnail: " + title} />
        </a>
      </div>
      <div className="text-info">
        <a href={`/article?id=${id}`}>
          <h2>{title}</h2>
        </a>
        <div className="sub-info">
          <p className="author">{author}</p>
          <p className="date">{formatedDate}</p>
        </div>
      </div>
    </div>
  )
}

export default Article
