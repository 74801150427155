import React, { useState, useEffect } from "react"
import { FiX } from "react-icons/fi"
import AOS from "aos"
import Article from "../../components/Article/Article"

import "aos/dist/aos.css"
import "./blogcomponent.scss"

const BlogComponent = () => {
  const guidBaseURLength = "https://medium.com/p/".length
  const [filtersElements, setFiltersElements] = useState({})
  const [articles, setArticles] = useState([
    {
      title: "Economia Low Touch — Mais tecnologia | Menos atrito",
      pubDate: "Wed, 19 Aug 2020 15:30:24 GMT",
      link: "",
      guid: "https://medium.com/p/333078ca509f",
      author: "FABWORK",
      thumbnail:
        "https://cdn-images-1.medium.com/fit/c/150/150/0*hMNpsAYbWHBqg2C_",
      description: "",
      content: "",
      enclosure: {},
      categories: [
        "novas-economias",
        "transformação-digital",
        "low-touch-economy",
        "tecnologia",
        "adaptação",
      ],
    },
  ])
  const [filters, setFilters] = useState([])

  function addFilter(filter) {
    if (!filters.find(filter_ => (filter_ === filter ? filter_ : 0))) {
      document.getElementById(filter).classList.add("active")
      return setFilters([...filters, filter])
    }

    const indexFilter = filters.indexOf(filter)
    filters.splice(indexFilter, 1)
    document.getElementById(filter).classList.remove("active")
    return setFilters([...filters])
  }

  function hasIdThisClass(elementId, classToSearch) {
    const element = filtersElements[elementId]
    if (element) return element.classList.contains(classToSearch)
    return false
  }

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
      offset: 50,
    })
  })

  useEffect(() => {
    setFiltersElements({
      "novas-economias": document.getElementById("novas-economias"),
      "transformação-digital": document.getElementById("transformação-digital"),
      "low-touch-economy": document.getElementById("low-touch-economy"),
      tecnologia: document.getElementById("tecnologia"),
      adaptação: document.getElementById("adaptação"),
    })
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@fabwork", //darius-foroux
      {
        method: "GET",
      }
    ).then(response => {
      response.json().then(json => {
        setArticles(json.items)
      })
    })
  }, [])

  useEffect(() => {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@fabwork", //darius-foroux
      {
        method: "GET",
      }
    ).then(response => {
      response.json().then(json => {
        const result = []

        if (!json.items) return

        json.items.forEach(item => {
          if (compareArray(item.categories) === filters.length) {
            result.push(item)
          }
        })

        return setArticles(result)
      })
    })

    function compareArray(toCompare = []) {
      const result = filters.filter(filter => {
        if (toCompare.indexOf(filter) >= 0) return filter
        return 0
      })
      return result.length
    }
  }, [filters])

  return (
    <div id="blog-page">
      <h1 className="title" data-aos="fade-up">
        Blog
      </h1>
      <div className="filters">
        <div data-aos="fade-up" data-aos-delay="100">
          <button
            id="novas-economias"
            className="btn"
            onClick={() => {
              addFilter("novas-economias")
            }}
          >
            Novas economias
            {hasIdThisClass("novas-economias", "active") ? (
              <FiX size={20} />
            ) : (
              ""
            )}
          </button>
        </div>
        <div data-aos="fade-up" data-aos-delay="150">
          <button
            id="transformação-digital"
            className="btn"
            onClick={() => {
              addFilter("transformação-digital")
            }}
          >
            Transformação digital
            {hasIdThisClass("transformação-digital", "active") ? (
              <FiX size={20} />
            ) : (
              ""
            )}
          </button>
        </div>
        <div data-aos="fade-up" data-aos-delay="200">
          <button
            id="low-touch-economy"
            className="btn"
            onClick={() => {
              addFilter("low-touch-economy")
            }}
          >
            Low touch economy
            {hasIdThisClass("low-touch-economy", "active") ? (
              <FiX size={20} />
            ) : (
              ""
            )}
          </button>
        </div>
        <div data-aos="fade-up" data-aos-delay="250">
          <button
            id="tecnologia"
            className="btn"
            onClick={() => {
              addFilter("tecnologia")
            }}
          >
            Tecnologia
            {hasIdThisClass("tecnologia", "active") ? <FiX size={20} /> : ""}
          </button>
        </div>
        <div data-aos="fade-up" data-aos-delay="300">
          <button
            id="adaptação"
            className="btn"
            onClick={() => {
              addFilter("adaptação")
            }}
          >
            Adaptação
            {hasIdThisClass("adaptação", "active") ? <FiX size={20} /> : ""}
          </button>
        </div>
      </div>
      <div className="articles">
        {articles.map((article, index) => (
          <div
            data-aos="fade-up"
            data-aos-delay={50 + 50 * ((index % 3) + 1)}
            key={article.guid.slice(guidBaseURLength)}
            className="article"
          >
            <Article
              type={"ala"}
              title={article.title}
              author={article.author}
              image={article.thumbnail}
              date={article.pubDate}
              id={article.guid.slice(guidBaseURLength)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default BlogComponent
