import React from "react"
import Helmet from "react-helmet"
import RDStationConection from "../utils/RDStationConnection"

import Header from "../components/Header/Header"
import BlogComponent from "../components/BlogComponent/BlogComponent"
import Footer from "../components/Footer/Footer"
import NeoronWidget from "../utils/NeoronWidget"

export default function BlogPage() {
  React.useEffect(() => {
    RDStationConection.init()
    NeoronWidget.init()
  }, [])
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog | FABWORK</title>
        <link rel="canonical" href="https://fab.work/blog/" />
      </Helmet>
      <Header />
      <BlogComponent />
      <Footer />
    </div>
  )
}
